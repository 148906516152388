<template>
    <div
        v-if="embedUrl"
        class="embed embed--video"
    >
        <iframe
            :src="embedUrl"
            loading="lazy"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
        />
    </div>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';

const props = defineProps<BaseEmbedProps>();

const containsYoutube = (str: string) => str && (
    str.includes('youtube.com') ||
    str.includes('youtu.be')
);

const privacyFriendly = (url: string) => url.includes('youtu.be');

const embedUrl = computed(() => {
    if (!props.code && !props.url) {
        return null;
    }

    let code = null;
    if(containsYoutube(props?.code)) {
        code = props.code;
    }
    if (containsYoutube(props?.url)){
        code = props.url;
    }

    if(!code) {
        return null;
    }

    // Direct video url
    if(!code.includes('embed')) {
        const url = new URL(code);
        let videoId;

        if(privacyFriendly(code)) {
            videoId = url.pathname.split('/').pop();
            return videoId ? `https://www.youtube-nocookie.com/embed/${videoId}` : null;
        }

        // Normal youtube url
        videoId = url.searchParams.get('v');
        return videoId ? `https://www.youtube-nocookie.com/embed/${videoId}` : null;
    }

    try {
        // Full embed code
        if(code.includes('<iframe')) {
            const src = code.match(/src="([^"]*)"/);
            return src?.[1];
        }

        // Direct embed url
        const url = new URL(code);
        return url.toString();

    } catch(e){
        // silence
        return null;
    }
});

</script>
